import { toast } from 'react-toastify'

import { StyledToast } from './styled'

const Toast = (): JSX.Element => (
  <StyledToast
    autoClose={3000}
    hideProgressBar={false}
    bodyClassName='grow-font-size'
    draggable
    position={toast.POSITION.BOTTOM_CENTER}
  />
)

export default Toast

const colors = {
  white: '#FFFFFF',
  pureBlack: '#000000',
  red: '#CF003E',
  red2: '#b2083b',
  yellow: '#FFD100',
  black: '#3D2B30',
  black2: '#1b1114',
  black3: '#4b3927',
  black35: 'rgba(0, 0, 0, 0.35)',
  gray: '#d8d8d8',
  gray2: '#c9c8c6',
  gray3: '#ACACAC',
  gray4: '#897e80',
  gray6: '#F2F2F2',
  brown: '#412E31',
  brown2: '#e1d3c5',
  brown3: '#d4b99f',
  blue: '#1877F2',
  blue2: '#1977f2',
  lightBlue: '#cfe8f9',
  blackOpacity: 'rgba(61,43,48,0.23)',
  transparent: 'transparent',
  redGradient: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #cf003e',
  secondaries: {
    opacity5: '#FDFCFB',
    opacity10: '#FBFAF8',
    opacity30: '#F4EFE9',
    opacity60: '#E9DED4',
    opacity80: '#E2D3C5',
    opacity100: '#DBC8B7',
  },
}

export default colors

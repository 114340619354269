import { ReactElement } from 'react'

import Head from 'next/head'
import { DefaultSeo } from 'next-seo'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from 'styled-components'
import CssBaseline from '@mui/material/CssBaseline'
import 'react-toastify/dist/ReactToastify.min.css'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider as MaterialProvider } from '@mui/material/styles'
import { SessionProvider, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

import 'public/fonts/fonts.css'
import { LOCALES_WITH_KEYS, rqClientConfig } from '@/config/constants'
import GlobalStyle from '@/config/styledGlobalStyle'
import theme from '@/config/theme'
import materialTheme from '@/config/materialTheme'
import { configureToken } from '@/services/index'
import Toast from '@/components/atoms/Toast'

import SEO from '../next-seo.config'

import type { AppProps } from 'next/app'

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const queryClient = new QueryClient(rqClientConfig)

  const { locale } = useRouter()
  const localeData = LOCALES_WITH_KEYS.find((o: { id: string }) => o.id === locale)
  const localeEn = LOCALES_WITH_KEYS.find((o: { id: string }) => o.id === 'en')
  const title = localeData?.title || localeEn?.title
  const description = localeData?.description || localeEn?.description

  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <GlobalStyle />
      <DefaultSeo {...SEO} />
      <Head>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
        <title>{title}</title>
        <meta name='description' content={description} key='description' />
        <meta property='og:title' content={title} key='title' />
        <meta property='og:description' content={description} key='description' />
        {locale === 'fr' && (
          <>
            <script
              id='fr-script'
              type='text/javascript'
              dangerouslySetInnerHTML={{
                __html: `
                (function (_window, _document) {
                  var ZEM_TAG_ID = '53943'
                  if (_window.zemApi) {
                    var toArray = function (object: string) {
                      return Object.prototype.toString.call(object) === '[object Array]' ? object : [object]
                    }
                    _window.zemApi.marketerId = toArray(_window.zemApi.marketerId).concat(toArray(ZEM_TAG_ID))
                    return
                  }
                  var api = (_window.zemApi = function () {
                    api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments)
                  })
                  api.version = '1.0'
                  api.loaded = true
                  api.marketerId = ZEM_TAG_ID
                  api.queue = []
                  var tag = _document.createElement('script')
                  tag.async = true
                  tag.src = '//js-tag.zemanta.com/zcpt.js'
                  tag.type = 'text/javascript'
                  var script = _document.getElementsByTagName('script')[0]
                  script.parentNode.insertBefore(tag, script)
                })(window, document);`,
              }}
            />
            <noscript>
              <img
                src='https://p1.zemanta.com/v2/p/ns/53943/PAGE_VIEW/'
                referrerPolicy='no-referrer-when-downgrade'
                height='1'
                width='1'
                alt=''
              />
            </noscript>
          </>
        )}
      </Head>
      <MaterialProvider theme={materialTheme}>
        <ThemeProvider theme={{ ...theme, ...materialTheme }}>
          <SessionProvider session={session} refetchOnWindowFocus={false}>
            <CheckSession>
              <Component {...pageProps} />
            </CheckSession>
          </SessionProvider>
          <Toast />
        </ThemeProvider>
      </MaterialProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

type CheckSessionProps = {
  children: ReactElement
}

const CheckSession = ({ children }: CheckSessionProps) => {
  const { status, data } = useSession()

  if (status === 'authenticated') {
    configureToken(data.jwt)
  }

  return children
}

export default appWithTranslation(MyApp)

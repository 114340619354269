import { createTheme } from '@mui/material/styles'

import { breakpoints } from './constants'

const materialTheme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
})

export default materialTheme

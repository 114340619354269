import { i18n } from 'next-i18next.config'

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || ''
export const DATABASE_URL = process.env.NEXT_PUBLIC_DATABASE_URL || ''

export const PREFIX_URL = process.env.NODE_ENV === 'development' ? '' : ''

export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET || ''
export const NEXTAUTH_URL = process.env.NEXTAUTH_URL || ''

export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID || ''
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET || ''
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || ''
export const FACEBOOK_CLIENT_ID = process.env.FACEBOOK_CLIENT_ID || ''
export const FACEBOOK_CLIENT_SECRET = process.env.FACEBOOK_CLIENT_SECRET || ''
export const MICROSOFT_CLIENT_ID = process.env.MICROSOFT_CLIENT_ID || ''
export const MICROSOFT_CLIENT_SECRET = process.env.MICROSOFT_CLIENT_SECRET || ''

export const DEFAULT_LOCALE = i18n.defaultLocale

export const LOCALES = i18n.locales
export const LOCALES_WITH_KEYS = i18n.localesWithKey

export const breakpoints = {
  xs: 320,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1440,
}

export const rqClientConfig = {
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
}

type ExternalUrls = {
  CORPORATE: string
  FOODSERVICE: string
  INSTITUTE: string
  CROP_TRUST: string
  PRIVACY: string
  CROP_BIODIVERSITY: { [key: string]: string }
}

export const EXTERNAL_URLS: ExternalUrls = {
  CORPORATE: 'https://www.drschaer.com/',
  FOODSERVICE: 'https://www.schaer-foodservice.com/',
  INSTITUTE: 'https://www.drschaer.com/uk/institute/home',
  CROP_TRUST: 'https://www.croptrust.org/mission/why-we-need-crop-diversity/',
  PRIVACY: 'https://www.schaer.com/en-int/privacy',
  CROP_BIODIVERSITY: {
    en: 'https://www.schaer.com/a/dr-schaer-100th-anniversary',
    de: 'https://www.drschaer.com/de/a/field100',
    it: 'https://www.schaer.com/it-it/a/dr-schaer-100esimo-anniversario',
    fr: 'https://www.schaer.com/fr-fr/a/dr-schaer-100-ans-anniversaire',
    si: 'https://brezglutena.schaer.com/clanki/100-let-dr-schar-od-pionirja-do-svetovnega-specialista-za-prehrano-brez-glutena',
    ro: 'https://www.schaer.com/ro-ro/a/100-de-ani-de-dr-schar',
    hr: 'https://www.schaer.com/da-dk/a/100-aar-med-dr-schar',
    'da-DK': 'https://www.schaer.com/da-dk/a/100-aar-med-dr-schar',
    'sr-Latn': 'https://www.schaer.com/sv-se/a/100-ar-med-dr-schar',
    'nb-NO': 'https://www.schaer.com/nb-no/a/100-ar-med-drschar',
    'cs-CZ': 'https://www.schaer.com/cs-cz/a/100-let-dr-schar',
    sk: 'https://www.schaer.com/sk-sk/a/100-rokov-dr-schar',
    hu: 'https://www.schaer.com/hu-hu/a/dr-schar-100-eve',
    'el-GR': 'https://www.schaer.com/el-gr/a/100-hronia-dr-schar',
    tr: 'https://www.schaer.com/tr-tr/a/dr-schaer-100-yil-donumu',
    'en-GB': 'https://www.schaer.com/en-uk/a/dr-schaer-100th-anniversary',
    pt: 'https://www.schaer.com/pt-br/a/dr-schar-100-anos',
    pl: 'https://www.schaer.com/pl-pl/a/100-lat-dr-schar',
    fi: 'https://www.schaer.com/fi-fi/a/dr-scharin-100-vuotisjuhlavuosi',
  },
}

export const SOCIAL_NETWORKS_IDS = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  MICROSOFT: 'azure-ad',
}

const path = require('path')

const defaultLocale = 'en'

const LOCALES = [
  {
    id: 'en',
    keyName: 'International English',
    privacyUrl: 'https://www.schaer.com/en-int/privacy',
    title: 'Schär | Plant Your Wish',
    description: 'Help us to conserve gluten free crops for the next 100 years.',
  },
  {
    id: 'en-US',
    keyName: 'English/US',
    privacyUrl: 'https://www.schaer.com/en-us/privacy',
  },
  {
    id: 'en-GB',
    keyName: 'English/GB',
    privacyUrl: 'https://www.schaer.com/en-uk/privacy',
  },
  {
    id: 'en-CA',
    keyName: 'English/CA',
    privacyUrl: 'https://www.schaer.com/en-ca/privacy',
  },
  {
    id: 'fr',
    keyName: 'French',
    privacyUrl: 'https://www.schaer.com/fr-fr/privacy',
  },
  {
    id: 'de',
    keyName: 'Deutsch',
    privacyUrl: 'https://www.schaer.com/de-de/privacy',
  },
  {
    id: 'it',
    keyName: 'Italian',
    privacyUrl: 'https://www.schaer.com/it-it/privacy',
  },
  {
    id: 'es',
    keyName: 'Spanish',
    privacyUrl: 'https://www.schaer.com/es-es/privacy',
  },
  {
    id: 'sr-Latn',
    keyName: 'Swedish',
    privacyUrl: 'https://www.schaer.com/sv-se/privacy',
  },
  {
    id: 'fi',
    keyName: 'Finnish',
    privacyUrl: 'https://www.schaer.com/fi-fi/privacy',
  },
  {
    id: 'nb-NO',
    keyName: 'Norwegian',
    privacyUrl: 'https://www.schaer.com/nb-no/privacy',
  },
  {
    id: 'da-DK',
    keyName: 'Danish',
    privacyUrl: 'https://www.schaer.com/da-dk/privacy',
  },
  {
    id: 'hu',
    keyName: 'Hungarian',
    privacyUrl: 'https://www.schaer.com/hu-hu/privacy',
  },
  {
    id: 'cs-CZ',
    keyName: 'Czech',
    privacyUrl: 'https://www.schaer.com/cs-cz/privacy',
  },
  {
    id: 'si',
    keyName: 'Slovenian',
    privacyUrl: 'https://www.schaer.com/en-int/privacy',
  },
  {
    id: 'el-GR',
    keyName: 'Greek',
    privacyUrl: 'https://www.schaer.com/en-int/privacy',
  },
  {
    id: 'sk',
    keyName: 'Slovak',
    privacyUrl: 'https://www.schaer.com/sk-sk/privacy',
  },
  {
    id: 'pl',
    keyName: 'Polish',
    privacyUrl: 'https://www.schaer.com/pl-pl/privacy',
  },
  {
    id: 'hr',
    keyName: 'Croatian',
    privacyUrl: 'https://www.schaer.com/en-int/privacy',
  },
  {
    id: 'pt',
    keyName: 'Brazilan',
    privacyUrl: 'https://www.schaer.com/pt-br/privacy',
    title: 'Plante seu desejo para o futuro',
    description: 'Nos ajude a conservar plantações sem glúten pelos próximos 100 anos. Para cada desejo.',
  },
  {
    id: 'tr',
    keyName: 'Turkish',
    privacyUrl: 'https://www.schaer.com/tr-tr/privacy',
  },
  {
    id: 'da',
    keyName: 'Dutch',
    privacyUrl: 'https://www.schaer.com/nl-nl/privacy',
  },
]

module.exports = {
  i18n: {
    defaultLocale,
    locales: LOCALES.map(v => v.id),
    localesWithKey: LOCALES,
    localeDetection: true,
  },
  localePath: path.resolve('public/locales'),
}

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-y: scroll;
  }
  
  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  .global-page-wrapper {
    flex: 1;
    position: relative;
  }
`

export default GlobalStyle

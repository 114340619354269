import axios from 'axios'

import * as ApiConfig from '@/config/api'

const instance = axios.create({
  baseURL: ApiConfig.ENTRY_POINT,
  headers: { 'Content-Type': 'application/json' },
})

instance.interceptors.response.use(
  response => response,
  async error => Promise.reject(error)
)

export function configureToken(token: string): void {
  configureAxiosDefaults('Authorization', token ? `Bearer ${token}` : '')
}

export function removeAxiosToken(): void {
  removeAxiosTokenHeader()
}

export function configureLanguage(language: string): void {
  configureAxiosDefaults('Accept-Language', language)
}

export function configureAxiosDefaults(header: string, value: string): void {
  instance.defaults.headers.common[header] = value
}

export function removeAxiosTokenHeader(): void {
  const header = 'Authorization'
  delete instance.defaults.headers.common[header]
}

export { instance }
